<template>
  <div style="width: 100%" v-if="isDealer || isOwner">
    <a-form
      style="width: 100%"
      v-if="!onlyList"
      class="ant-advanced-search-form"
      name="advanced_search"
    >
      <div class="filterFields">
        <a-form-item v-if="isOwner" label="ИНН" class="filterFormItems">
          <a-input
            @change="e => setFilterInn(e.target.value)"
            :value="filters.inn"
            style="margin-bottom: 5px"
            placeholder="ИНН"
          ></a-input>
        </a-form-item>
        <a-form-item label="Код дилера" class="filterFormItems">
          <a-input
            @change="e => setFilterName(e.target.value)"
            :value="filters.name"
            style="margin-bottom: 5px"
            placeholder="Код дилера"
          ></a-input>
        </a-form-item>
        <a-form-item v-if="isDealer" label="ИНН" class="filterFormItems">
          <a-input
            @change="e => setFilterInn(e.target.value)"
            :value="filters.inn"
            style="margin-bottom: 5px"
            placeholder="ИНН"
          ></a-input>
        </a-form-item>
      </div>
      <div class="buttons">
        <a-button type="primary" @click="searchClients">Поиск</a-button>
        <a-button @click="handleReset">Очистить</a-button>
      </div>
    </a-form>

    <div v-if="!onlyList" class="top-buttons">
      <a-button
        v-if="isManufacturerAdmin"
        @click="onCreateClientClick"
        type="primary"
        icon="user-add"
        class="user-add"
      >
        Создать Дилера
      </a-button>
      <a-button-group v-if="false">
        <a-tooltip placement="topLeft" title="Выгрузить">
          <a-button icon="download" />
        </a-tooltip>
        <a-tooltip placement="topLeft" title="Печать">
          <a-button icon="printer" />
        </a-tooltip>
      </a-button-group>
    </div>

    <div class="content-container">
      <a-table
        bordered
        :columns="columnsList"
        :data-source="clients"
        :customRow="customRow"
        :rowKey="row => row.id"
        class="ordertable"
        :pagination="false"
      >
        <template slot="operation" slot-scope="text, record">
          <div>
            <a-popconfirm
              title="Уверены, что хотите переместить дилера в архив?"
              ok-text="Архив"
              cancel-text="Отмена"
              placement="topLeft"
              @confirm="event => onDelete(event, record.id)"
            >
              <a-button
                v-if="record.status === 'active'"
                type="danger"
                ghost
                shape="circle"
                icon="delete"
                size="small"
                @click="onDelete"
              />
            </a-popconfirm>
          </div>
        </template>
      </a-table>

      <a-pagination
        class="pagination"
        v-model="currentPage"
        :page-size="paginationOptions.pageSize"
        :total="paginationOptions.totalCount"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"
function onChange(pagination, filters, sorter) {
  console.log("params", pagination, filters, sorter)
}

export default {
  props: {
    onlyList: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.fetchClients()
  },
  computed: {
    ...mapGetters({
      columns: "clients/columns",
      clients: "clients/clients",
      filters: "clients/filters",
      currentDealer: "dealers/currentDealer",
      isOwner: "auth/isOwner",
      isDealer: "auth/isDealer",
      isManufacturerAdmin: "auth/isManufacturerAdmin",
      paginationOptions: "clients/paginationOptions",
    }),

    columnsList() {
      if (this.isManufacturerAdmin) {
        return this.columns
      }

      const list = [...this.columns]
      list.pop()
      return list
    },

    currentPage: {
      get() {
        return this.paginationOptions.currentPage
      },
      set(page) {
        this.setCurrentPage(page)
      },
    },
  },
  watch: {
    async currentPage(page) {
      if (page !== 1) this.fetchClients()
      this.loading = true
      await this.fetchClients()
      this.loading = false
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      form: this.$form.createForm(this, { name: "advanced_search" }),
    }
  },
  methods: {
    onChange,
    ...mapMutations({
      setFilterPaymentGroup: "clients/SET_FILTER_PAYMENT_GROUP",
      setFilterName: "clients/SET_FILTER_NAME",
      setFilterInn: "clients/SET_FILTER_INN",
      clearFilters: "clients/CLEAR_FILTERS",
      setCurrentPage: "clients/SET_CURRENT_PAGE",
    }),
    ...mapActions({
      searchClients: "clients/searchClients",
      fetchClients: "clients/fetchClients",
      deleteClient: "clients/deleteClient",
    }),

    onPaymentGroupChange(value) {
      this.form.setFieldsValue({
        paymentGroup: value,
      })
    },
    async onDelete(event, key) {
      event.stopPropagation()
      if (key || key === 0) {
        await this.deleteClient(key)
        await this.fetchClients()
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onCreateClientClick() {
      this.$router.push("/checkclient")
    },
    handleReset() {
      this.form.resetFields()
      this.clearFilters()
      this.fetchClients()
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.$router.push(`/clients/${record.id}`)
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ml-10 {
  margin-left: 10px;
}
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.top-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 -5px;
  > button {
    &:first-child {
      margin-right: 10px;
    }
  }
}
.filterFields {
  display: flex;
  flex-direction: row;
}
.filterFormItems {
  display: block;
  margin-right: 20px;
}
.selects {
  width: 200px;
}
</style>

<style>
th.column-action,
td.column-action {
  text-align: center !important;
}
</style>
