<template>
  <div>
    <h3>Рассылка прайс-листов</h3>
    <ClientList />
  </div>
</template>

<script>
import ClientList from "@/components/clients/ClientList"

export default {
  name: "PricesDelivery",
  components: {
    ClientList,
  },
}
</script>

<style scoped></style>
